<template>
  <div class="fixed-page">
    <div class="content">
      <Breadcrumb class="breadcrumb-1400" />
      <div class="detailBox">
        <exhibitBanner :imgList="exhibitInfo.image" />
        <div class="detailContent">
          <div class="detailTitle">{{ exhibitInfo.title }}</div>
          <div class="tagBox">
            <div class="tag">标签：</div>
            <template v-if="exhibitInfo.tag.length">
              <div class="tagItem" v-for="item in exhibitInfo.tag" :key="item">
                {{ item }}
              </div>
            </template>
          </div>
          <div class="intro">
            简介：
            <div style="text-align: justify">{{ exhibitInfo.body }}</div>
          </div>
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
// import { swiper, swiperSlide } from "vue-awesome-swiper";
import { getExhibitDetail } from "@/service/theatreMuseum";
import exhibitBanner from "./exhibitBanner.vue";
import { formatRichText } from "@/utils/formatRichText";
export default {
  components: {
    // swiper,
    // swiperSlide,
    exhibitBanner,
  },
  created () {
    if (this.$route.query.id) {
      this.getExhibitDetailInfo(this.$route.query.id);
    }
  },
  mounted () { },
  data () {
    return {
      exhibitInfo: {},
    };
  },

  methods: {
    async getExhibitDetailInfo (id) {
      const res = await getExhibitDetail(id);
      this.exhibitInfo = res.data;
      this.exhibitInfo.image = this.exhibitInfo.image.split(",");
      this.exhibitInfo.tag = this.exhibitInfo.tag
        ? this.exhibitInfo.tag.split(",")
        : [];
      this.exhibitInfo.body = formatRichText(this.exhibitInfo.body);
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  background: #f8f8fa;
  padding-top: 1.0417vw;
  padding-bottom: 2.6042vw;
  .detailBox {
    display: flex;
    width: 72.9167vw;
    min-height: 26.0417vw;
    margin: auto;
    padding: 2.6042vw 0 2.6042vw 2.6042vw;
    background-color: #fff;
    .detailContent {
      margin: 0 4.1667vw;
      .detailTitle {
        font-size: 1.25vw;
        font-weight: 700;
        margin-top: 1.0417vw;
      }
      .date {
        font-size: 0.7292vw;
        color: #999;
        margin: 0.7813vw 0;
      }
      .tagBox {
        display: flex;
        margin: 1.0417vw 0;
        .tag {
          font-size: 0.7292vw;
          color: #999;
        }
        .tagItem {
          width: 3.3333vw;
          height: 1.0417vw;
          background: #f8d39f;
          border-radius: 0.1042vw 0.1042vw 0.1042vw 0.1042vw;
          text-align: center;
          line-height: 1.0417vw;
          margin-left: 0.5208vw;
          font-size: 0.5729vw;
          font-weight: 500;
        }
      }
      .intro {
        width: 46.3542vw;
        height: 2.9167vw;
        font-size: 0.8333vw;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #606266;
        line-height: 1.1979vw;
      }
    }
  }
}
</style>
