<template>
  <div class="imageGroup clearfix">
    <swiper :options="swiperOptionTop" class="gallery-top" ref="swiperTop">
      <swiper-slide v-for="item in imgList" :key="item.id">
        <img :src="item.image" class="img" @error="imgOnError($event)" />
        <div class="viewIntro" @click="viewIntro(item.id)">
          <img src="@/assets/img/theatreMuseum/intro.svg" alt="" />
          <span>查看简介</span>
        </div>
        <img
          src="@/assets/img/theatreMuseum/close.svg"
          @click="closeView"
          class="close"
          alt=""
          v-if="isView"
        />
        <transition name="fade">
          <div class="bannerIntro" v-if="isView">
            <div class="text">
              <div v-html="item.body"></div>
            </div>
          </div>
        </transition>
      </swiper-slide>
      <div
        class="swiper-button-next swiper-button-white"
        slot="button-next"
      ></div>
      <div
        class="swiper-button-prev swiper-button-white"
        slot="button-prev"
      ></div>
    </swiper>
    <!-- swiper2 Thumbs -->
    <div class="thumbsBox swiper-no-swiping">
      <swiper
        :options="swiperOptionThumbs"
        class="gallery-thumbs"
        ref="swiperThumbs"
      >
        <swiper-slide
          v-for="(item, index) in imgList"
          :key="index"
          :class="{ active: swiperobj1.activeIndex === index }"
        >
          <img :src="item.image" class="img" @error="imgOnError($event)" />
          <div class="location" @click="setIndex(index)">
            <div>{{ item.title }}</div>
            <div style="font-size: 0.7813vw">——</div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import slotImg from "@/assets/img/slotImg.jpg";
import { portalHit } from "@/service/ArtsInformation";
export default {
  name: "imageGroup",
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    height: {
      type: String,
      default: "5.2083vw",
    },
    imgList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      swiperOptionTop: {
        spaceBetween: 0,
        autoplay: {
          //自动轮播
          delay: 5000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        spaceBetween: 12,
        slidesPerView: 8,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      },
      isMounted: false,
      isView: false,
    };
  },
  computed: {
    swiperobj1() {
      if (!this.isMounted) return { index: 0 };
      return this.$refs.swiperTop.swiper;
    },
    swiperobj2() {
      if (!this.isMounted) return;
      return this.$refs.swiperThumbs.swiper;
    },
  },
  watch: {
    "swiperobj1.activeIndex": {
      handler(n, o) {
        if (n) {
          this.isView = false;
          this.swiperobj1.autoplay.run();
          this.swiperobj2.autoplay.run();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper;
      const swiperThumbs = this.$refs.swiperThumbs.swiper;
      // swiperTop.controller.control = swiperThumbs
      // swiperThumbs.controller.control = swiperTop
      swiperTop.thumbs.swiper = swiperThumbs;
      this.isMounted = true;
    });
  },
  methods: {
    change(type) {
      let activeIndex = this.swiperobj1.activeIndex;
      if (type === "prev" && activeIndex !== 0) {
        this.swiperobj1.slideTo(activeIndex - 1, 100);
        this.swiperobj2.slideTo(activeIndex - 1, 100);
      }
      if (type === "next" && activeIndex !== this.imgList.length) {
        this.swiperobj1.slideTo(activeIndex + 1, 100);
        this.swiperobj2.slideTo(activeIndex + 1, 100);
      }
    },
    setIndex(index) {
      this.swiperobj1.slideTo(index, 100);
      this.swiperobj2.slideTo(index, 100);
    },
    imgOnError(e) {
      let img = e.srcElement;
      img.src = slotImg;
      img.onerror = null; //防止闪图
    },
    viewIntro(id) {
      this.isView = !this.isView;
      if (this.isView) {
        portalHit(id);
      }
      if (this.isView) {
        this.swiperobj1.autoplay.pause();
        this.swiperobj2.autoplay.pause();
      } else {
        this.swiperobj1.autoplay.run();
        this.swiperobj2.autoplay.run();
      }
    },
    closeView() {
      this.isView = false;
      this.swiperobj1.autoplay.run();
      this.swiperobj2.autoplay.run();
    },
  },
};
</script>
<style lang="less" scoped>
.imageGroup,
.swiperBox,
.imgBox {
  height: 41.6667vw;
}
.imageGroup {
  position: relative;
}
.swiper-slide {
  background-size: cover;
  background-position: center;
}
.gallery-top {
  height: 41.6667vw !important;
  width: 100%;
}
.viewIntro {
  position: absolute;
  top: 4.6875vw;
  left: 11.1979vw;
  display: flex;
  align-items: center;
  font-size: 1.0417vw;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  img {
    width: 1.875vw;
    height: 1.875vw;
    margin-right: 0.2604vw;
  }
}
.close {
  position: absolute;
  top: 6.7708vw;
  left: 48.4375vw;
  width: 1.5625vw;
  height: 1.5625vw;
  cursor: pointer;
  z-index: 2;
}
.bannerIntro {
  position: absolute;
  top: 6.5104vw;
  left: 11.1979vw;
  width: 39.375vw;
  height: 22.3958vw;
  background: #000000;
  opacity: 0.7;
  padding: 1.4063vw 2.6042vw 2.6563vw 2.6042vw;
  box-sizing: border-box;

  .text {
    width: 34.1667vw;
    height: 18.3333vw;
    font-size: 1.25vw;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    line-height: 2.2917vw;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.location {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 1.0417vw;
  color: #fff;
  background-color: #00000069;
  padding: 5.2083vw 0 1.0417vw 0.7813vw;
  box-sizing: border-box;
}
.thumbsBox {
  padding: 0 1.0417vw;
  height: 20% !important;
  position: relative;
  top: -9.375vw;
  left: 0;
}
.gallery-thumbs {
  height: 100% !important;
  box-sizing: border-box;
  padding: 0.0521vw 0;
  display: flex;
  justify-content: center;
}
.gallery-thumbs .swiper-slide {
  height: 100%;
  border: 0.0521vw solid #ccc;
  box-sizing: border-box;
}
// .gallery-thumbs .swiper-slide-active {
//   border: 1px solid #2D9AE9;
// }
.gallery-thumbs .active {
  border: 0.1042vw solid #f8d39f;
}
.prevBtn,
.nextBtn {
  color: #666;
  position: absolute;
  font-size: 1.5625vw;
  font-weight: bold;
  top: calc(50% - 0.7813vw);
}
.prevBtn {
  left: -0.1563vw;
}
.nextBtn {
  right: -0.1563vw;
}
.btnDisabled {
  color: #ccc;
  cursor: not-allowed;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
::v-deep .swiper-wrapper {
  width: auto;
}
/* 修改滚动条的高度和宽度 */
::-webkit-scrollbar {
  width: 0.5208vw; /* 滚动条宽度 */
  height: 0.5208vw; /* 滚动条高度 */
}

/* 修改滚动条的滑块样式 */
::-webkit-scrollbar-thumb {
  background-color: #888; /* 滑块颜色 */
  border-radius: 0.2604vw; /* 滑块圆角 */
}

/* 修改滚动条的轨道样式 */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* 轨道颜色 */
  border-radius: 0.2604vw; /* 轨道圆角 */
}
</style>
