<template>
  <div>
    <exhibitBanner :imgList="exhibitInfo.image" />
    <div class="infoBox">
      <div class="title">展品：{{ exhibitInfo.title }}</div>
      <div class="date">年代：{{ exhibitInfo.years }}</div>
      <div class="tagBox">
        <div class="tag">标签：</div>
        <template v-if="exhibitInfo.tag.length">
          <div class="tagItem" v-for="item in exhibitInfo.tag" :key="item">
            {{ item }}
          </div>
        </template>
      </div>
      <div class="intro">
        <div class="i1">简介：{{ exhibitInfo.body }}</div>
        <!-- <span class="i2"></span> -->
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import exhibitBanner from "./exhibitBanner.vue";
import { getExhibitDetail } from "@/service/theatreMuseum";
export default {
  components: {
    exhibitBanner,
  },
  created () {
    if (this.$route.query.id) {
      this.getExhibitDetailInfo(this.$route.query.id);
    }
  },
  data () {
    return {
      exhibitInfo: {},
    };
  },
  methods: {
    async getExhibitDetailInfo (id) {
      const res = await getExhibitDetail(id);
      this.exhibitInfo = res.data;
      this.exhibitInfo.image = this.exhibitInfo.image.split(",");
      this.exhibitInfo.tag = this.exhibitInfo.tag
        ? this.exhibitInfo.tag.split(",")
        : [];
    },
  },
};
</script>

<style lang="less" scoped>
.infoBox {
  margin-top: 0.5333rem;
  padding: 0 0.2667rem;
  box-sizing: border-box;
  .title {
    font-size: 0.4rem;
    font-weight: 800;
    margin-bottom: 0.4rem;
  }
  .date {
    font-size: 0.3333rem;
    font-weight: 400;
    color: #909399;
  }
  .tagBox {
    display: flex;
    align-items: center;
    margin: 0.2667rem 0;
    .tag {
      font-size: 0.3333rem;
      color: #999;
    }
    .tagItem {
      width: 1.5rem;
      height: 0.4rem;
      background: #f8d39f;
      border-radius: 0.0267rem;
      text-align: center;
      line-height: 0.4rem;
      margin-left: 0.1333rem;
      font-size: 0.2267rem;
      font-weight: 500;
    }
  }
  .intro {
    // display: flex;
    // justify-content: space-around;
    font-size: 0.3333rem;
    color: #999;
    margin-bottom: 0.8rem;
    .i1 {
      // width: 1.0667rem;
      text-align: justify;
    }
    .i2 {
      width: 9.3333rem;
      text-align: justify;
    }
  }
}
</style>
