<template>
  <div>
    <div class="title">{{ digitalInfo.title }}</div>
    <div class="location">
      <div class="l1">展览地点：</div>
      <div class="l2">{{ digitalInfo.publishPlace }}</div>
    </div>
    <div class="location">
      <div class="l1">展览时间：</div>
      <div class="l2">{{ digitalInfo.publishDate }}</div>
    </div>
    <div class="introContent">
      <div class="introTitle">
        <img src="../../../assets/img/renyiOverview/jiantou.png" />
        <span class="title">展览简介</span>
      </div>
      <div class="richTextBox">
        <div v-html="digitalInfo.body"></div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import { getDigitalDetail } from "@/service/theatreMuseum";
import { formatRichText } from "@/utils/formatRichText";
export default {
  data () {
    return {
      digitalInfo: {},
    };
  },
  created () {
    if (this.$route.query.id) {
      this.getDigitalDetailInfo(this.$route.query.id);
    }
  },
  methods: {
    async getDigitalDetailInfo (id) {
      const res = await getDigitalDetail(id);
      this.digitalInfo = res.data;
      this.digitalInfo.body = formatRichText(this.digitalInfo.body);
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 0.48rem;
  font-weight: 800;
  margin: 0.4rem 0.2667rem;
}
.location {
  display: flex;
  font-size: 0.3333rem;
  margin: 0.2667rem 0.1333rem;
  .l1 {
    color: #999;
  }
}
.introContent {
  margin-top: 0.3467rem;

  .introTitle {
    display: flex;
    align-items: center;
    padding-left: 0.4rem;
    box-sizing: border-box;
    img {
      width: 0.24rem;
      height: 0.4533rem;
      margin-right: 0.2133rem;
    }
    .title {
      font-size: 0.4533rem;
      font-weight: 800;
      color: #212122;
    }
  }
  .richTextBox {
    min-height: 12rem;
    margin-bottom: 0.6667rem;
  }
}
</style>
