<template>
  <div class="introContent">
    <div class="introTitle">
      <div>
        <img src="../../../assets/img/renyiOverview/jiantou.png" />
        <span class="title">数字展览</span>
      </div>
    </div>
    <div class="digitalList">
      <div
        class="digital-item"
        v-for="(item, index) in digitalList"
        :key="index"
        @click="gotoDigitalDetail(item.id)"
      >
        <img :src="item.image" alt="" @error="imgOnError($event)" />
        <div class="digitalInfo">
          <div class="digitalBottomTitlte">{{ item.title }}</div>
          <div class="locationName">{{ item.publishPlace }}</div>
          <div class="date">
            {{ item.publishDate }}
          </div>
        </div>
      </div>
    </div>
    <div class="loadBox"></div>
    <PageFooter />
  </div>
</template>

<script>
import { getDigitalList, chickDigital } from "@/service/theatreMuseum";
import slotImg from "@/assets/img/slotImg.jpg";
export default {
  data() {
    return {
      digitalList: [],
      isLoading: false,
      loadingInfo: null,
      query: {
        condition: { status: 1 },
        pageNo: 1,
        pageSize: 9999,
      },
      total: 0,
    };
  },
  created() {
    this.getDigitalPage(this.query);
    window.addEventListener("scroll", this.lazyLoading); // 滚动到底部，再加载的处理事件
  },
  //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题
  destroyed() {
    window.removeEventListener("scroll", this.lazyLoading);
    //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题
  },
  methods: {
    getDigitalPage(params) {
      getDigitalList(params).then((res) => {
        if (this.isLoading) {
          this.loadingInfo = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            target: document.querySelector(".loadBox"),
          });
          this.digitalList =
            this.digitalList.concat(
              res.data.data.sort((a, b) => {
                const dateA = new Date(a.publishDate);
                const dateB = new Date(b.publishDate);
                return dateB - dateA;
              }) || []
            ) || [];
          this.isLoading = false;
          return this.loadingInfo.close();
        }
        this.digitalList =
          this.digitalList.concat(
            res.data.data.sort((a, b) => {
              const dateA = new Date(a.publishDate);
              const dateB = new Date(b.publishDate);
              return dateB - dateA;
            }) || []
          ) || [];
        this.total = res.data.totalCount;
      });
    },
    gotoDigitalDetail(item) {
      chickDigital(item);
      // const { href } = this.$router.resolve({
      //   path: "/mobile/theatreMuseum/exhibitDetail",
      //   query: {
      //     id: item,
      //   },
      // });
      // window.open(href, "_blank");
      this.$router.push({
        path: "/theatreMuseum/digitalDetail",
        query: {
          id: item,
        },
      });
    },
    lazyLoading() {
      // 滚动到底部，再加载的处理事件
      // 获取 可视区高度`、`滚动高度`、`页面高度`
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        // 滚动到底部，逻辑代码
        //事件处理
        this.query.pageNo += 1;
        if (this.query.pageNo * this.query.pageSize >= this.total && this.total)
          return false;
        this.isLoading = true;
        this.getExhibitPage(this.query);
        // 这这里可以写一些业务逻辑，请求数据等、
      }
    },
    imgOnError(e) {
      let img = e.srcElement;
      img.src = slotImg;
      img.onerror = null; //防止闪图
    },
  },
};
</script>

<style lang="less" scoped>
.introContent {
  margin-top: 0.5333rem;

  .introTitle {
    display: flex;
    align-items: center;
    padding-left: 0.4rem;
    box-sizing: border-box;
    img {
      width: 0.24rem;
      height: 0.4533rem;
      margin-right: 0.2133rem;
    }
    .title {
      font-size: 0.4533rem;
      font-weight: 800;
      color: #212122;
    }
  }
  .digitalList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0.8rem;
    .digital-item {
      display: flex;
      justify-content: space-around;
      margin-top: 0.4rem;
      img {
        display: inline-block;
        width: 3.84rem;
        height: 2.4267rem;
        border-radius: 0.2133rem;
        object-fit: cover;
      }
      .digitalInfo {
        display: flex;
        flex-direction: column;
        color: #212122;
        margin-left: 0.2667rem;
        .digitalBottomTitlte {
          width: 5.0933rem;
          font-size: 0.3467rem;
          font-weight: 800;
          margin-top: 0.1333rem;
        }
        .locationName {
          font-size: 0.2667rem;
          font-weight: 400;
          margin: 0.32rem 0 0.4933rem 0;
        }
        .date {
          font-size: 0.2667rem;
          font-weight: 400;
          color: #909399;
        }
      }
    }
  }
}
.loadBox {
  width: 100%;
  height: 0.6667rem;
}
::v-deep .el-icon-loading {
  font-size: 0.4rem;
}
</style>
