<template>
  <div class="imageGroup clearfix">
    <swiper :options="swiperOptionTop" class="gallery-top" ref="swiperTop">
      <swiper-slide v-for="(item, index) in imgList" :key="index">
        <img :src="item" class="img" @error="imgOnError($event)" />
      </swiper-slide>
    </swiper>
    <!-- swiper2 Thumbs -->
    <div class="thumbsBox">
      <!-- <i
        @click="change('prev')"
        class="el-icon-arrow-left prevBtn"
        :class="{ btnDisabled: swiperobj1.activeIndex === 0 }"
      ></i> -->
      <swiper
        :options="swiperOptionThumbs"
        class="gallery-thumbs"
        ref="swiperThumbs"
      >
        <swiper-slide
          v-for="(item, index) in imgList"
          :key="index"
          :class="{ active: swiperobj1.activeIndex === index }"
        >
          <img
            @click="setIndex(index)"
            :src="item"
            class="img"
            @error="imgOnError($event)"
          />
        </swiper-slide>
      </swiper>
      <!-- <i
        @click="change('next')"
        class="el-icon-arrow-right nextBtn"
        :class="{ btnDisabled: swiperobj1.activeIndex === imgList.length - 1 }"
      ></i> -->
    </div>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import slotImg from "@/assets/img/slotImg.jpg";
export default {
  name: "imageGroup",
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    height: {
      type: String,
      default: "100px",
    },
    imgList: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      swiperOptionTop: {
        spaceBetween: 10,
        autoplay: {
          //自动轮播
          delay: 5000,
          disableOnInteraction: false,
        },
      },
      swiperOptionThumbs: {
        spaceBetween: 10,
        slidesPerView: 3,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      },
      isMounted: false,
    };
  },
  computed: {
    swiperobj1 () {
      if (!this.isMounted) return { index: 0 };
      return this.$refs.swiperTop.swiper;
    },
    swiperobj2 () {
      if (!this.isMounted) return;
      return this.$refs.swiperThumbs.swiper;
    },
  },
  mounted () {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper;
      const swiperThumbs = this.$refs.swiperThumbs.swiper;
      // swiperTop.controller.control = swiperThumbs
      // swiperThumbs.controller.control = swiperTop
      swiperTop.thumbs.swiper = swiperThumbs;
      this.isMounted = true;
    });
  },
  methods: {
    change (type) {
      let activeIndex = this.swiperobj1.activeIndex;
      if (type === "prev" && activeIndex !== 0) {
        this.swiperobj1.slideTo(activeIndex - 1, 100);
        this.swiperobj2.slideTo(activeIndex - 1, 100);
      }
      if (type === "next" && activeIndex !== this.imgList.length) {
        this.swiperobj1.slideTo(activeIndex + 1, 100);
        this.swiperobj2.slideTo(activeIndex + 1, 100);
      }
    },
    setIndex (index) {
      this.swiperobj1.slideTo(index, 100);
      this.swiperobj2.slideTo(index, 100);
    },
    imgOnError (e) {
      let img = e.srcElement;
      img.src = slotImg;
      img.onerror = null; //防止闪图
    },
  },
};
</script>
<style lang="less" scoped>
.imageGroup,
.swiperBox,
.imgBox {
  width: 10rem;
}
.swiper-slide {
  background-size: cover;
  background-position: center;
}
.gallery-top {
  height: 6.6667rem !important;
  width: 10rem;
}
.thumbsBox {
  // padding: 0 20px;
  width: 10rem;
  height: 2rem !important;
  position: relative;
}
.gallery-thumbs {
  width: 10rem;
  height: 2rem !important;
  box-sizing: border-box;
  padding: 0.0133rem 0;
}
.gallery-thumbs .swiper-slide {
  height: 2rem;
  border: 0.0133rem solid #ccc;
  box-sizing: border-box;
}
// .gallery-thumbs .swiper-slide-active {
//   border: 1px solid #2D9AE9;
// }
.gallery-thumbs .active {
  border: 0.0133rem solid #f8d39f;
}
.prevBtn,
.nextBtn {
  color: #7c7c7c;
  position: absolute;
  font-size: 0.4rem;
  font-weight: bold;
  top: calc(50% - 0.4267rem);
  // background-color: #d1cdcd7a;
  height: 65px;
  line-height: 65px;
}
.prevBtn {
  left: -3px;
}
.nextBtn {
  right: -3px;
}
.btnDisabled {
  color: #ccc;
  cursor: not-allowed;
}
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
