<template>
  <div class="fixed-page">
    <div class="content">
      <Breadcrumb class="breadcrumb-1400" />
      <div class="detailBox">
        <div class="detailTitle">展览：{{ digitalInfo.title }}</div>
        <div class="location">展览地点：{{ digitalInfo.publishPlace }}</div>
        <div class="location">展览时间：{{ digitalInfo.publishDate }}</div>
        <div class="viewTitle">
          <img src="../../../assets/img/renyiOverview/jiantou.png" />
          <span class="title">数字展览</span>
        </div>
        <div v-html="digitalInfo.body"></div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import { getDigitalDetail } from "@/service/theatreMuseum";
import { formatRichText } from "@/utils/formatRichText";
export default {
  data() {
    return {
      digitalInfo: {},
    };
  },
  created() {
    if (this.$route.query.id) {
      this.getDigitalDetailInfo(this.$route.query.id);
    }
  },
  methods: {
    async getDigitalDetailInfo(id) {
      const res = await getDigitalDetail(id);
      this.digitalInfo = res.data;
      this.digitalInfo.body = this.changeImg(
        formatRichText(this.digitalInfo.body)
      );
    },
    changeImg(html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const ulTags = doc.querySelectorAll("img");

      ulTags.forEach(function (img) {
        img.style.imageRendering = "pixelated";
        img.style.objectFit = "contain";
      });

      return doc.documentElement.innerHTML;
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  padding-top: 1.0417vw;
  padding-bottom: 2.6042vw;
  .detailBox {
    width: 72.9167vw;
    margin: auto;
    padding: 2.6042vw;
    box-sizing: border-box;
    background-color: #fff;
    .detailTitle {
      font-size: 1.0417vw;
      font-weight: 700;
    }
    .location {
      font-size: 0.7813vw;
      color: #999;
      margin-top: 0.5208vw;
    }
    .viewTitle {
      margin-top: 2.0833vw;
      img {
        width: 0.7292vw;
        height: 1.3542vw;
        margin-right: 0.625vw;
      }
      .title {
        font-size: 1.3542vw;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #212122;
      }
    }
  }
}
</style>
