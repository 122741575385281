<template>
  <div class="introContent">
    <div class="introTitle">
      <div>
        <img src="../../../assets/img/renyiOverview/jiantou.png" />
        <span class="title">陈列展品</span>
      </div>
    </div>
    <div class="exhibitList">
      <div
        class="exhibit-item"
        v-for="(item, index) in exhibitsList"
        :key="index"
        @click="gotoExhibitDetail(item.id)"
      >
        <img :src="item.image" alt="" @error="imgOnError($event)" />
        <div class="exhibitsName">{{ item.title }}</div>
      </div>
    </div>
    <div class="loadBox"></div>
    <PageFooter />
  </div>
</template>

<script>
import { getExhibitList, chickExihibit } from "@/service/theatreMuseum";
import slotImg from "@/assets/img/slotImg.jpg";
export default {
  data() {
    return {
      exhibitsList: [],
      isLoading: false,
      loadingInfo: null,
      query: {
        condition: { status: 1 },
        pageNo: 1,
        pageSize: 9999,
      },
      total: 0,
    };
  },
  created() {
    this.getExhibitPage(this.query);
    window.addEventListener("scroll", this.lazyLoading); // 滚动到底部，再加载的处理事件
  },
  //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题
  destroyed() {
    window.removeEventListener("scroll", this.lazyLoading);
    //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题
  },
  methods: {
    getExhibitPage(params) {
      getExhibitList(params).then((res) => {
        if (this.isLoading) {
          this.loadingInfo = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            target: document.querySelector(".loadBox"),
          });
          this.exhibitsList = this.exhibitsList.concat(res.data.data) || [];
          this.isLoading = false;
          return this.loadingInfo.close();
        }
        this.exhibitsList = this.exhibitsList.concat(res.data.data) || [];
        this.total = res.data.totalCount;
      });
    },
    gotoExhibitDetail(item) {
      chickExihibit(item);
      // const { href } = this.$router.resolve({
      //   path: "/mobile/theatreMuseum/exhibitDetail",
      //   query: {
      //     id: item,
      //   },
      // });
      // window.open(href, "_blank");
      this.$router.push({
        path: "/theatreMuseum/exhibitDetail",
        query: {
          id: item,
        },
      });
    },
    lazyLoading() {
      // 滚动到底部，再加载的处理事件
      // 获取 可视区高度`、`滚动高度`、`页面高度`
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        // 滚动到底部，逻辑代码
        //事件处理
        this.query.pageNo += 1;
        if (this.query.pageNo * this.query.pageSize >= this.total && this.total)
          return false;
        this.isLoading = true;
        this.getExhibitPage(this.query);
        // 这这里可以写一些业务逻辑，请求数据等、
      }
    },
    imgOnError(e) {
      let img = e.srcElement;
      img.src = slotImg;
      img.onerror = null; //防止闪图
    },
  },
};
</script>

<style lang="less" scoped>
.introContent {
  margin-top: 0.5333rem;

  .introTitle {
    display: flex;
    align-items: center;
    padding-left: 0.4rem;
    box-sizing: border-box;
    img {
      width: 0.24rem;
      height: 0.4533rem;
      margin-right: 0.2133rem;
    }
    .title {
      font-size: 0.4533rem;
      font-weight: 800;
      color: #212122;
    }
  }
  .exhibitList {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-top: 0.4rem;
    box-sizing: border-box;
    .exhibit-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0.24rem;
      background-color: #f8f8fa;
      img {
        display: inline-block;
        width: 4.48rem;
        height: 4.64rem;
        border-radius: 0.16rem 0.16rem 0 0;
        object-fit: cover;
      }
      .exhibitsName {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.48rem;
        height: 0.8267rem;
        font-size: 0.3067rem;
        color: #212122;
        text-align: center;
      }
    }
  }
}
.loadBox {
  width: 100%;
  height: 0.6667rem;
}
::v-deep .el-icon-loading {
  font-size: 0.4rem;
}
</style>
