<template>
  <div class="fixed-page">
    <div class="content">
      <Breadcrumb class="breadcrumb-1400" />
      <div class="digitalBox">
        <div class="digitalTitle">
          <img src="../../../assets/img/renyiOverview/jiantou.png" />
          <span class="title">数字展览</span>
        </div>
        <div class="digitalList">
          <div
            class="digitalItem"
            v-for="item in exhibitsList"
            :key="item.id"
            @click="gotoExhibitDetail(item.id)"
          >
            <img :src="item.image" alt="" @error="imgOnError($event)" />
            <div class="digitalT">
              <div class="digitalTitle">
                {{ item.title }}
              </div>
              <div class="digitalLocation">{{ item.publishPlace }}</div>
              <div class="date">{{ item.publishDate }}</div>
            </div>
          </div>
        </div>
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="query.pageNo"
            :page-size="16"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import { getDigitalList, chickDigital } from "@/service/theatreMuseum";
import slotImg from "@/assets/img/slotImg.jpg";
export default {
  data() {
    return {
      query: {
        pageNo: 1,
        pageSize: 16,
      },
      total: 0,
      exhibitsList: [],
    };
  },
  created() {
    this.getExhibitPage(this.query);
  },
  methods: {
    async getExhibitPage(params) {
      const res = await getDigitalList({
        condition: { status: 1 },
        ...params,
      });
      this.exhibitsList =
        res.data.data.sort((a, b) => {
          const dateA = new Date(a.publishDate);
          const dateB = new Date(b.publishDate);
          return dateB - dateA;
        }) || [];
      this.total = res.data.totalCount;
    },
    gotoExhibitDetail(item) {
      chickDigital(item);
      const { href } = this.$router.resolve({
        path: "/theatreMuseum/digitalDetail",
        query: {
          id: item,
        },
      });
      window.open(href, "_blank");
    },
    handleCurrentChange(val) {
      this.query.pageNo = val;
      this.getExhibitPage(this.query);
    },
    imgOnError(e) {
      let img = e.srcElement;
      img.src = slotImg;
      img.onerror = null; //防止闪图
    },
  },
};
</script>

<style lang="less" scoped>
.block {
  margin-top: 3.125vw;
  //   margin-bottom: 52px;
}
::v-deep .el-pagination {
  display: flex;
  justify-content: center;
}
::v-deep .el-pager li {
  width: 1.6667vw;
  height: 1.6667vw;
  background: #ffffff;
  border-radius: 0.3125vw 0.3125vw 0.3125vw 0.3125vw;
  text-align: center;
  line-height: 1.6667vw;
  border: 0.0521vw solid rgba(0, 0, 0, 0.149);
  margin-right: 0.4167vw;
}
::v-deep .el-pager li:last-child {
  margin-right: 0;
}
::v-deep .el-pager .active {
  color: #f9680d;
  border: 0.0521vw solid #f9680d;
}
.content {
  background: #f5f6f8;
  padding-top: 1.0417vw;
  padding-bottom: 2.6042vw;
  .digitalBox {
    width: 72.9167vw;
    margin: auto;
    padding: 2.6042vw;
    box-sizing: border-box;
    background-color: #fff;
    .digitalTitle {
      img {
        width: 0.7292vw;
        height: 1.3542vw;
        margin-right: 0.625vw;
      }
      .title {
        font-size: 1.3542vw;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #212122;
      }
    }
    .digitalList {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 0.7813vw;
      .digitalItem {
        width: 20.8333vw;
        height: 22.3958vw;
        margin-bottom: 2.6042vw;
        cursor: pointer;
        img {
          width: 20.8333vw;
          height: 15.9375vw;
        }
        .digitalT {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          width: 20.8333vw;
          height: 6.4583vw;
          background: #f8f8fa;
          padding: 0 1.0417vw;
          box-sizing: border-box;
          .digitalTitle {
            font-size: 1.0417vw;
            font-weight: 600;
          }
          .digitalLocation {
            font-size: 0.7813vw;
          }
          .date {
            font-size: 0.625vw;
            color: #999;
          }
        }
      }
    }
  }
}
img {
  object-fit: cover;
}
</style>
