<template>
  <div class="fixed-page">
    <banner :imgList="bannerList" />
    <div class="contentBox">
      <div class="contentTitle">
        <img src="../../../assets/img/renyiOverview/jiantou.png" />
        <span class="title">戏剧博物馆</span>
      </div>
      <div class="content">
        <div v-html="jianjie"></div>
      </div>
    </div>
    <div class="exhibitsBox">
      <div class="exhibitsTitle">
        <div>
          <img src="../../../assets/img/renyiOverview/jiantou.png" />
          <span class="title">陈列展品</span>
        </div>
        <div class="more" @click="gotoExhibitList">查看更多</div>
      </div>
      <div class="exhibitsList">
        <div
          class="exhibitsItem"
          v-for="item in exhibitsList"
          :key="item.id"
          @click="gotoExhibitDetail(item.id)"
        >
          <img :src="item.image[0]" alt="" @error="imgOnError($event)" />
          <div class="exhibitsName">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="digitalBox">
      <div class="digitalTitle">
        <div>
          <img src="../../../assets/img/renyiOverview/jiantou.png" />
          <span class="title">数字展览</span>
        </div>
        <div class="more" @click="gotoDigitalList">查看更多</div>
      </div>
      <div class="digitalTopBox">
        <div class="digitalTop" @click="gotoDigitalDetail(digitalList[0].id)">
          <div class="topImg">
            <img
              :src="digitalList[0].image"
              alt=""
              @error="imgOnError($event)"
            />
          </div>
          <div class="topL">
            <div class="topLtitle">{{ digitalList[0].title }}</div>
            <div style="width: 32.8125vw; height: 5.3125vw; overflow: hidden">
              <div v-html="digitalList[0].body"></div>
            </div>
            <div class="topLB">
              <div class="location">
                <span class="loctioinT">展览地点：</span>
                <span class="loctioinC">{{ digitalList[0].publishPlace }}</span>
              </div>
              <div class="location">
                <span class="loctioinT">展览日期：</span>
                <span class="loctioinC">{{ digitalList[0].publishDate }}</span>
              </div>
            </div>
          </div>
          <img
            class="contentBg"
            src="../../../assets/img/renyiOverview/numView.png"
            alt=""
          />
        </div>
      </div>
      <div class="digitalBottomList">
        <div
          class="digitalBottomItem"
          v-for="item in digitalList.slice(1)"
          :key="item.id"
          @click="gotoDigitalDetail(item.id)"
        >
          <div class="digitalBottomTitlte">
            {{ item.title }}
          </div>
          <div class="digitalBottomImg">
            <div class="digitalBottomDate">
              <div class="dateT1">
                {{ item.publishDate.substring(8,10)}}
              </div>
              <div class="dateT2">
                {{ item.publishDate.substring(0, 7) }}
              </div>
            </div>
            <img :src="item.image" alt="" @error="imgOnError($event)" />
          </div>
          <div class="locationName">{{ item.publishPlace }}</div>
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import banner from "./banner.vue";
import {
  getExhibitList,
  chickExihibit,
  getDigitalList,
  chickDigital,
  getTopBanner,
  getMuseumDetail,
} from "@/service/theatreMuseum";
import slotImg from "@/assets/img/slotImg.jpg";
export default {
  components: {
    banner,
  },
  created() {
    this.getBannerList();
    this.getExhibitPage();
    this.getDigitalPage();
  },
  data() {
    return {
      bannerQuery: {
        condition: {
          level1: "rotation",
          level2: "museum",
        },
        pageNo: 1,
        pageSize: 6,
      },
      bannerQuery2: {
        level1: "MUSEUM",
        level2: "jianjie",
      },
      bannerList: [],
      jianjie: null,
      exhibitsList: [],
      digitalList: [],
    };
  },

  methods: {
    async getBannerList() {
      const res1 = await getTopBanner(this.bannerQuery);
      const res2 = await getMuseumDetail(this.bannerQuery2);
      this.bannerList = res1.data.data;
      this.jianjie = res2.data.body;
    },
    async getExhibitPage() {
      const res = await getExhibitList({
        condition: { status: 1 },
        pageNo: 1,
        pageSize: 8,
      });
      this.exhibitsList = res.data.data || [];
      for (let i = 0; i < this.exhibitsList.length; i++) {
        this.exhibitsList[i].image = this.exhibitsList[i].image.split(",");
      }
    },
    async getDigitalPage() {
      const res = await getDigitalList({
        condition: { status: 1 },
        pageNo: 1,
        pageSize: 100,
      });
      this.digitalList =
        res.data.data
          .sort((a, b) => {
            const dateA = new Date(a.publishDate);
            const dateB = new Date(b.publishDate);
            return dateB - dateA;
          })
          .slice(0, 4) || [];
    },
    gotoExhibitList() {
      const { href } = this.$router.resolve({
        path: "/theatreMuseum/exhibitList",
      });
      window.open(href, "_blank");
    },
    gotoExhibitDetail(item) {
      chickExihibit(item);
      const { href } = this.$router.resolve({
        path: "/theatreMuseum/exhibitDetail",
        query: {
          id: item,
        },
      });
      window.open(href, "_blank");
    },
    gotoDigitalList() {
      const { href } = this.$router.resolve({
        path: "/theatreMuseum/digitalList",
      });
      window.open(href, "_blank");
    },
    gotoDigitalDetail(item) {
      chickDigital(item);
      const { href } = this.$router.resolve({
        path: "/theatreMuseum/digitalDetail",
        query: {
          id: item,
        },
      });
      window.open(href, "_blank");
    },
    imgOnError(e) {
      let img = e.srcElement;
      img.src = slotImg;
      img.onerror = null; //防止闪图
    },
  },
};
</script>
<style lang="less" scoped>
.fixed-page {
  background: #f8f8fa;
  height: calc(100vh - 4.1667vw);
  width: 100%;
  text-align: left;
  overflow-y: auto;
}
h3 {
  margin: 1.0417vw 0 0 0.5208vw;
}
.slide-1 {
  position: relative;
  width: 100%;
  .imgIntroBox {
    position: absolute;
    top: 4.9479vw;
    left: 11.1979vw;
    width: 39.375vw;
    height: 22.3958vw;
    background: #000000;
    opacity: 0.8;
    padding: 1.4063vw 2.6042vw 2.6563vw 2.6042vw;
    box-sizing: border-box;
    .imgIntro {
      width: 34.1667vw;
      height: 18.3333vw;
      font-size: 1.25vw;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      line-height: 2.2917vw;
    }
  }
}
.slide-2 {
  position: relative;
  .imgTitle {
    position: absolute;
    bottom: 0.7813vw;
    left: 1.0417vw;
    color: #fff;
  }
}
.thumb-example {
  position: relative;
  width: 100%;
  // height: 1000px;
}
.swiper-slide {
  background-size: cover;
  background-position: center;
}
.gallery-top {
  // height: 80% !important;
  height: 41.6667vw;
  width: 100%;
}

.bottom_swiper {
  position: absolute;
  left: 0;
  bottom: 2.0833vw;
  width: 100%;
  .bottom_swiper_box {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.gallery-thumbs {
  height: 20% !important;
  box-sizing: border-box;
  padding: 0.5208vw 0;
  width: 70.8333vw;
  margin-left: 0.1042vw;
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    background: #fff;
    width: 2.3438vw;
    text-align: center;
    height: 5.2604vw;
    top: 26%;
    div {
      margin-top: 1.5625vw;
      background: rgb(207, 205, 205);
      height: 2.3438vw;
      border-radius: 50%;
      img {
        margin: 0.3646vw 0 0 0;
        width: 1.5625vw;
      }
    }
  }
  .swiper-button-next:hover div {
    background: rgb(189, 186, 186);
  }
  .swiper-button-prev:hover div {
    background: rgb(189, 186, 186);
  }
}
.gallery-thumbs .swiper-slide {
  width: 20%;
  height: 4.1667vw;
  // opacity: 0.4;
}
.gallery-thumbs .swiper-slide-active {
  border: 0.1042vw solid red;
}

::v-deep .swiper-container {
  margin-right: 0;
}
.top_swiper {
  // width: 100%!important;
  ::v-deep .swiper-slide {
    // margin-left: 0 !important;
    // margin-right: 0 !important;
  }
}

::v-deep .swiper-button-prev {
  width: 3.125vw;
  height: 3.125vw;
  background-size: 3.125vw 3.125vw;
  background-image: url("../../../assets/img/renyiOverview/left.png");
}
::v-deep .swiper-button-next {
  width: 3.125vw;
  height: 3.125vw;
  background-size: 3.125vw 3.125vw;
  background-image: url("../../../assets/img/renyiOverview/right.png");
}
// ::v-deep .gallery-thumbs .swiper-slide-active {
//   border: 2px solid #f8d39f;
// }
.contentBox {
  width: 72.9167vw;
  margin: 2.0833vw auto 1.7188vw auto;
  padding: 2.6042vw;
  background-image: url(../../../assets/img/renyiOverview/contentBg.png);
  box-sizing: border-box;
  .contentTitle {
    img {
      width: 0.7292vw;
      height: 1.3542vw;
      margin-right: 0.625vw;
    }
    .title {
      font-size: 1.3542vw;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #212122;
    }
  }
  .content {
    width: 67.7083vw;
    font-size: 0.8333vw;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #212122;
    margin-top: 1.0417vw;
    text-align: justify;
    line-height: 1.7708vw;
  }
}
.exhibitsBox {
  width: 72.9167vw;
  height: 47.7604vw;
  padding: 2.9688vw 2.6042vw 2.6042vw 2.6042vw;
  margin: 1.7188vw auto 2.0833vw auto;
  box-sizing: border-box;
  background-color: #fff;
  .exhibitsTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.0417vw;
    img {
      width: 0.7292vw;
      height: 1.3542vw;
      margin-right: 0.625vw;
    }
    .title {
      font-size: 1.3542vw;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #212122;
    }
    .more {
      font-size: 0.8333vw;
      color: #f9680d;
      cursor: pointer;
    }
  }
  .exhibitsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .exhibitsItem {
      display: flex;
      flex-direction: column;
      width: 15.3646vw;
      height: 18.8542vw;
      background: #f8f8fa;
      margin-bottom: 2.0833vw;
      cursor: pointer;
      img {
        width: 15.3646vw;
        height: 15.9375vw;
      }
      .exhibitsName {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 2.9167vw;
        font-size: 0.8333vw;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #212122;
      }
    }
  }
}
.digitalBox {
  width: 72.9167vw;
  height: 50.2604vw;
  padding: 2.9688vw 2.6042vw 2.6042vw 2.6042vw;
  margin: 33px auto 40px auto;
  box-sizing: border-box;
  background-color: #fff;
  .digitalTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.0417vw;
    img {
      width: 0.7292vw;
      height: 1.3542vw;
      margin-right: 0.625vw;
    }
    .title {
      font-size: 1.3542vw;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #212122;
    }
    .more {
      font-size: 0.8333vw;
      color: #f9680d;
      cursor: pointer;
    }
  }
  .digitalTopBox {
    position: relative;
    cursor: pointer;
    .digitalTop {
      display: flex;
      .contentBg {
        position: absolute;
        bottom: 0;
        right: 2.6042vw;
        width: 8.3333vw;
        height: 7.0313vw;
      }
      .topImg {
        width: 33.3333vw;
        height: 18.75vw;
        img {
          width: 33.3333vw;
          height: 18.75vw;
        }
      }
      .topL {
        padding: 1.5625vw 2.0833vw;
        box-sizing: border-box;
        .topLtitle {
          font-size: 1.25vw;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #212122;
          margin-bottom: 1.0417vw;
        }
        .topLContent {
          width: 32.8125vw;
          height: 5.3125vw;
          font-size: 0.8333vw;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #606266;
          line-height: 1.7708vw;
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 3.8542vw;
        }
        .topLB {
          margin-top: 3.8542vw;
          .location {
            font-size: 0.8333vw;
            .loctioinT {
              color: #999;
            }
            .locationC {
              font-size: 0.8333vw;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #909399;
            }
          }
        }
      }
    }
  }
  .digitalBottomList {
    display: flex;
    justify-content: space-between;
    margin-top: 3.8542vw;
    .digitalBottomItem {
      width: 20.8333vw;
      height: 19.6875vw;
      cursor: pointer;
      .digitalBottomTitlte {
        width: 20.8333vw;
        height: 3.5417vw;
        font-size: 1.25vw;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #212122;
        line-height: 1.7708vw;
      }
      .digitalBottomImg {
        position: relative;
        width: 20.8333vw;
        height: 11.7188vw;
        font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
        color: #212122;
        margin-top: 2.1875vw;
        .digitalBottomDate {
          position: absolute;
          top: -1.1458vw;
          left: 0;
          width: 4.8958vw;
          height: 4.8958vw;
          background: #f8d39f;
          text-align: center;
          line-height: 4.8958vw;
          .dateT1 {
            font-size: 2.2917vw;
            font-weight: 800;
            line-height: 3.4375vw;
          }
          .dateT2 {
            font-size: 0.7292vw;
            font-weight: 500;
            line-height: 1.0938vw;
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .locationName {
        font-size: 1.0417vw;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #212122;
        margin-top: 1.1979vw;
      }
    }
  }
}
img {
  object-fit: cover;
}
</style>
