<template>
  <div class="imageGroup clearfix">
    <swiper :options="swiperOptionTop" class="gallery-top" ref="swiperTop">
      <swiper-slide v-for="(item, index) in imgList" :key="index">
        <!-- <img
          :src="item"
          class="img"
          @error="imgOnError($event)"
          style="object-fit: contain"
        /> -->
        <div class="flexc">
          <div class="img1 topimgBox">
            <el-image
              :hide-on-click-modal="true"
              style="display: inline-block"
              :src="item"
              :preview-src-list="imgList"
              @error="imgOnError($event)"
            >
            </el-image>
            <div class="mask">
              <div class="mask1">
                <img src="@/assets/img/theatreMuseum/zoomin.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <!-- swiper2 Thumbs -->
    <div class="thumbsBox">
      <i
        @click="change('prev')"
        class="el-icon-arrow-left prevBtn"
        :class="{ btnDisabled: swiperobj1.activeIndex === 0 }"
      ></i>
      <swiper
        :options="swiperOptionThumbs"
        class="gallery-thumbs"
        ref="swiperThumbs"
      >
        <swiper-slide
          v-for="(item, index) in imgList"
          :key="index"
          :class="{ active: swiperobj1.activeIndex === index }"
        >
          <img
            @click="setIndex(index)"
            :src="item"
            class="img"
            @error="imgOnError($event)"
          />
        </swiper-slide>
      </swiper>
      <i
        @click="change('next')"
        class="el-icon-arrow-right nextBtn"
        :class="{ btnDisabled: swiperobj1.activeIndex === imgList.length - 1 }"
      ></i>
    </div>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import slotImg from "@/assets/img/slotImg.jpg";
export default {
  name: "imageGroup",
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    height: {
      type: String,
      default: "5.2083vw",
    },
    imgList: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      swiperOptionTop: {
        spaceBetween: 10,
        autoplay: {
          //自动轮播
          delay: 5000,
          disableOnInteraction: false,
        },
      },
      swiperOptionThumbs: {
        spaceBetween: 10,
        slidesPerView: 3,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      },
      isMounted: false,
    };
  },
  computed: {
    swiperobj1 () {
      if (!this.isMounted) return { index: 0 };
      return this.$refs.swiperTop.swiper;
    },
    swiperobj2 () {
      if (!this.isMounted) return;
      return this.$refs.swiperThumbs.swiper;
    },
  },
  mounted () {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper;
      const swiperThumbs = this.$refs.swiperThumbs.swiper;
      // swiperTop.controller.control = swiperThumbs
      // swiperThumbs.controller.control = swiperTop
      swiperTop.thumbs.swiper = swiperThumbs;
      this.isMounted = true;
    });
  },
  methods: {
    change (type) {
      let activeIndex = this.swiperobj1.activeIndex;
      if (type === "prev" && activeIndex !== 0) {
        this.swiperobj1.slideTo(activeIndex - 1, 100);
        this.swiperobj2.slideTo(activeIndex - 1, 100);
      }
      if (type === "next" && activeIndex !== this.imgList.length) {
        this.swiperobj1.slideTo(activeIndex + 1, 100);
        this.swiperobj2.slideTo(activeIndex + 1, 100);
      }
    },
    setIndex (index) {
      this.swiperobj1.slideTo(index, 100);
      this.swiperobj2.slideTo(index, 100);
    },
    imgOnError (e) {
      let img = e.srcElement;
      img.src = slotImg;
      img.onerror = null; //防止闪图
    },
  },
};
</script>
<style lang="less" scoped>
.imageGroup,
.swiperBox,
.imgBox {
  width: 18.2292vw;
  max-height: 15.625vw;
}
.swiper-slide {
  background-size: cover;
  background-position: center;
}
.gallery-top {
  height: 15.625vw !important;
  width: 18.2292vw;
}
.thumbsBox {
  // padding: 0 20px;
  width: 18.2292vw;
  height: 3.4375vw !important;
  position: relative;
}
.gallery-thumbs {
  width: 15.625vw;
  height: 3.4375vw !important;
  box-sizing: border-box;
  padding: 0.0521vw 0;
}
.gallery-thumbs .swiper-slide {
  height: 3.4375vw;
  border: 0.0521vw solid #ccc;
  box-sizing: border-box;
}
// .gallery-thumbs .swiper-slide-active {
//   border: 1px solid #2D9AE9;
// }
.gallery-thumbs .active {
  border: 0.0521vw solid #f8d39f;
}
.prevBtn,
.nextBtn {
  color: #7c7c7c;
  position: absolute;
  font-size: 1.5625vw;
  font-weight: bold;
  top: calc(50% - 1.6667vw);
  // background-color: #d1cdcd7a;
  height: 3.3854vw;
  line-height: 3.3854vw;
}
.prevBtn {
  left: -0.1563vw;
}
.nextBtn {
  right: -0.1563vw;
}
.btnDisabled {
  color: #ccc;
  cursor: not-allowed;
}
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img1 {
  display: inline-block;
}
.img2 {
  max-width: 18.2292vw;
  max-height: 15.625vw;
  object-fit: contain;
}
.topimgBox {
  position: relative;
}
.topimgBox:hover .mask {
  display: block;
  animation: slide-in 0.5s forwards;
}
@keyframes slide-in {
  100% {
    opacity: 0.4;
  }
}
.mask {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 100%;
  background-color: #000;
  opacity: 0;
  z-index: 99;
  cursor: pointer;
  pointer-events: none;
  .mask1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    img {
      width: 2.6042vw;
      height: 2.6042vw;
    }
  }
}
.flexc {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .el-image img {
  max-width: 18.2292vw;
  max-height: 15.625vw;
  // width: 200px;
  // height: 300px;
  object-fit: contain;
}
</style>
