<template>
  <div>
    <banner :img-list="bannerList" />
    <div class="introContent">
      <div class="introTitle">
        <img src="../../../assets/img/renyiOverview/jiantou.png" />
        <span class="title">戏剧博物馆</span>
      </div>
      <div>
        <div class="richTextBox" v-html="jianjie"></div>
      </div>
    </div>
    <div class="introContent">
      <div class="introTitle1">
        <div>
          <img src="../../../assets/img/renyiOverview/jiantou.png" />
          <span class="title">陈列展品</span>
        </div>
        <div class="more" @click="gotoExhibitList">
          更多 <i class="el-icon-right"></i>
        </div>
      </div>
      <div class="exhibitList">
        <div
          class="exhibit-item"
          v-for="(item, index) in exhibitsList"
          :key="index"
          @click="gotoExhibitDetail(item.id)"
        >
          <img :src="item.image[0]" alt="" @error="imgOnError($event)" />
          <div class="exhibitsName">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="introContent">
      <div class="introTitle1">
        <div>
          <img src="../../../assets/img/renyiOverview/jiantou.png" />
          <span class="title">数字展览</span>
        </div>
        <div class="more" @click="gotoDigitalList">
          更多 <i class="el-icon-right"></i>
        </div>
      </div>
      <div class="digitalList">
        <div
          class="digital-item"
          v-for="(item, index) in digitalList"
          :key="index"
          @click="gotoDigitalDetail(item.id)"
        >
          <img :src="item.image" alt="" @error="imgOnError($event)" />
          <div class="digitalInfo">
            <div class="digitalBottomTitlte">{{ item.title }}</div>
            <div class="locationName">{{ item.publishPlace }}</div>
            <div class="date">
              {{ item.publishDate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import banner from "./banner.vue";
import {
  getExhibitList,
  chickExihibit,
  getDigitalList,
  chickDigital,
  getTopBanner,
  getMuseumDetail,
} from "@/service/theatreMuseum";
import slotImg from "@/assets/img/slotImg.jpg";
export default {
  components: {
    banner,
  },
  data() {
    return {
      bannerQuery: {
        condition: {
          level1: "rotation",
          level2: "museum",
        },
        pageNo: 1,
        pageSize: 6,
      },
      bannerQuery2: {
        level1: "MUSEUM",
        level2: "jianjie",
      },
      bannerList: [],
      jianjie: null,
      exhibitsList: [],
      digitalList: [],
    };
  },
  created() {
    this.getBannerList();
    this.getExhibitPage();
    this.getDigitalPage();
  },
  methods: {
    async getBannerList() {
      const res1 = await getTopBanner(this.bannerQuery);
      const res2 = await getMuseumDetail(this.bannerQuery2);
      this.bannerList = res1.data.data;
      this.jianjie = res2.data.body;
    },
    async getExhibitPage() {
      const res = await getExhibitList({
        condition: { status: 1 },
        pageNo: 1,
        pageSize: 8,
      });
      this.exhibitsList = res.data.data || [];
      for (let i = 0; i < this.exhibitsList.length; i++) {
        this.exhibitsList[i].image = this.exhibitsList[i].image.split(",");
      }
    },
    async getDigitalPage() {
      const res = await getDigitalList({
        condition: { status: 1 },
        pageNo: 1,
        pageSize: 100,
      });
      this.digitalList =
        res.data.data
          .sort((a, b) => {
            const dateA = new Date(a.publishDate);
            const dateB = new Date(b.publishDate);
            return dateB - dateA;
          })
          .slice(0, 3) || [];
    },
    gotoExhibitList() {
      // const { href } = this.$router.resolve({
      //   path: "/theatreMuseum/exhibitList",
      // });
      // window.open(href, "_blank");
      this.$router.push("/theatreMuseum/exhibitList");
    },
    gotoExhibitDetail(item) {
      chickExihibit(item);
      // const { href } = this.$router.resolve({
      //   path: "/mobile/theatreMuseum/exhibitDetail",
      //   query: {
      //     id: item,
      //   },
      // });
      // window.open(href, "_blank");
      this.$router.push({
        path: "/theatreMuseum/exhibitDetail",
        query: {
          id: item,
        },
      });
    },
    gotoDigitalList() {
      // const { href } = this.$router.resolve({
      //   path: "/mobile/theatreMuseum/digitalList",
      // });
      // window.open(href, "_blank");
      this.$router.push("/theatreMuseum/digitalList");
    },
    gotoDigitalDetail(item) {
      chickDigital(item);
      // const { href } = this.$router.resolve({
      //   path: "/mobile/theatreMuseum/digitalDetail",
      //   query: {
      //     id: item,
      //   },
      // });
      // window.open(href, "_blank");
      this.$router.push({
        path: "/theatreMuseum/digitalDetail",
        query: {
          id: item,
        },
      });
    },
    imgOnError(e) {
      let img = e.srcElement;
      img.src = slotImg;
      img.onerror = null; //防止闪图
    },
  },
};
</script>

<style lang="less" scoped>
.introContent {
  margin-top: 0.5333rem;

  .introTitle {
    display: flex;
    align-items: center;
    padding-left: 0.4rem;
    box-sizing: border-box;
    img {
      width: 0.24rem;
      height: 0.4533rem;
      margin-right: 0.2133rem;
    }
    .title {
      font-size: 0.4533rem;
      font-weight: 800;
      color: #212122;
    }
  }
  .introTitle1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.4rem;
    box-sizing: border-box;
    img {
      width: 0.24rem;
      height: 0.4533rem;
      margin-right: 0.2133rem;
    }
    .title {
      font-size: 0.4533rem;
      font-weight: 800;
      color: #212122;
    }
    .more {
      font-size: 0.2933rem;
    }
  }
  .richTextBox {
    width: 9.2rem;
    margin: 0 auto 0.6667rem;
    font-size: 0.35rem;
  }

  .exhibitList {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-top: 0.4rem;
    box-sizing: border-box;
    .exhibit-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0.4rem;
      background-color: #f8f8fa;
      img {
        display: inline-block;
        width: 4.48rem;
        height: 4.64rem;
        border-radius: 0.16rem 0.16rem 0 0;
        object-fit: cover;
      }
      .exhibitsName {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.48rem;
        height: 0.9rem;
        font-size: 0.3067rem;
        color: #212122;
        text-align: center;
      }
    }
  }
  .digitalList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 9.3333rem;
    padding-top: 0.4rem;
    margin-bottom: 0.8rem;
    .digital-item {
      display: flex;
      justify-content: space-around;
      img {
        display: inline-block;
        width: 3.84rem;
        height: 2.4267rem;
        border-radius: 0.2133rem;
        object-fit: cover;
      }
      .digitalInfo {
        display: flex;
        flex-direction: column;
        color: #212122;
        margin-left: 0.2667rem;
        .digitalBottomTitlte {
          width: 5.0933rem;
          font-size: 0.3467rem;
          font-weight: 800;
          margin-top: 0.1333rem;
        }
        .locationName {
          font-size: 0.2667rem;
          font-weight: 400;
          margin: 0.32rem 0 0.4933rem 0;
        }
        .date {
          font-size: 0.2667rem;
          font-weight: 400;
          color: #909399;
        }
      }
    }
  }
}
</style>
